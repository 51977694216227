import {_, React, Component, className, lib} from 'components'; //eslint-disable-line
import Slideshow from 'components/slideshow/slideshow';
import {Popup} from 'henrybuilt-react-library';
import './icon-grid.scss';

class IconGrid extends Component {
  state = {
    activeOptions: {},
    activeCategory: {}
  }

  componentDidMount() {
    this.setActiveOptionsUsingProps();
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.activeOptions, this.props.activeOptions)) {
      this.setActiveOptionsUsingProps();
    }
  }

  setActiveOptionsUsingProps = () =>  {
    if (this.props.activeOptions) this.setState({activeOptions: this.props.activeOptions});
  }

  setActive = ({activeOption, activeCategory}) => {
    if (this.props.onSelect) this.props.onSelect({selectedOption: activeOption, selectedCategory: activeCategory});

    var activeOptions = _.extend(this.state.activeOptions, {[activeCategory.key ? activeCategory.key : activeCategory.id]: activeOption});

    if (this.props.collapseCategories && activeCategory.index === this.state.activeCategory.index) {
      activeCategory = {};
    }

    this.setState({activeOptions, activeCategory});
  }

  render() {
    var {setActive} = this;
    var {activeOptions, activeCategory} = this.state;
    var {categories, optionKey, detailType, usePopup=false, collapseCategories=false} = this.props;

    var activeCategoryKey = activeCategory.key || activeCategory.id;

    return (
      <div className='icon-grid detail'>
        {_.map(categories, (category, index) => {
          var activeOption = activeOptions[_.get(category, 'key', _.get(category, 'id'))] || {};

          category.index = index;

          return <Swatches key={index} {...{category, activeOption, activeCategory, setActive, optionKey, detailType, collapseCategories}}/>;
        })}
        {usePopup && !_.isEmpty(activeOptions[activeCategoryKey]) && (
          <Popup onClose={() => setActive({activeOption: {}, activeCategory})} position='right'>
            <div className='popup-option-title'>{activeOptions[activeCategoryKey].title}</div>
            <Swatches
              defaultOption={activeOptions[activeCategoryKey]}
              category={activeCategory}
              {...{activeOption: activeOptions[activeCategoryKey], setActive, optionKey, detailType}}
            />
            <div className='popup-option-description'>{activeOptions[activeCategoryKey].description || ''}</div>
            <Slideshow key={activeOptions[activeCategoryKey].id} media={activeOptions[activeCategoryKey].media}/>
          </Popup>
        )}
      </div>
    );
  }
}

class Swatches extends Component {
  state = {
    activeOption: {},
    hoveredOption: {}
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.activeOption, this.props.activeOption)) {
      this.setState({activeOption: this.props.activeOption}, this.setDefaultHoveredOption);
    }
  }

  setDefaultHoveredOption = () => {
    this.setState({hoveredOption: this.state.activeOption || this.props.defaultOption || {}});
  }

  setActiveOption = ({activeOption, activeCategory}) => {
    this.props.setActive({activeOption, activeCategory});

    this.setState({activeOption});
  }

  componentDidMount() {
    this.setDefaultHoveredOption();
  }

  render() {
    var {setActiveOption} = this;
    var {hoveredOption} = this.state;
    var {category={}, activeOption={}, activeCategory, optionKey, detailType, collapseCategories} = this.props;

    var title = category.title ? `${_.startCase(category.title)} ${_.startCase(detailType)}:` : '';

    var shouldDisplayIcons = !(collapseCategories && category.index !== activeCategory.index);

    if (_.some(category[optionKey], {materialTypeId: 20})) var note = 'fabric selections are subject to availability';

    return (
      <div className='option-category'>
        <div className='option-meta'>
          <div
            {...className(['option-category-title', collapseCategories && 'clickable'])}
            onClick={collapseCategories ? () => setActiveOption({activeOption, activeCategory: category}) : () => {}}
          >{title} {hoveredOption.title}</div>
          {note && <div className='note'>{note}</div>}
        </div>
        {shouldDisplayIcons && <div className='option-swatches-container' onMouseLeave={() => this.setDefaultHoveredOption()}>
          {_.map(category[optionKey], option => (
              <div
                key={option.id}
                onMouseEnter={() => this.setState({hoveredOption: option})}
                style={{backgroundImage: `url(${_.get(option, 'iconMedium.url')})`}}
                {...className(['option-swatch', (activeOption.id === option.id) && 'selected'])}
                onClick={() => setActiveOption({activeOption: option, activeCategory: category})}
              />
            ))}
        </div>}
      </div>
    );
  }
}

export default IconGrid;

import {_, React, Component, className, lib} from 'components'; //eslint-disable-line
import './product-groups-grid.scss';
import {Link} from 'react-router-dom';

export default function ProductGroupsGrid({productGroups, groupMode}) {
  productGroups = _.map(productGroups, productGroup => {
    var media = _.filter(productGroup.media, medium => _.get(medium, 'products.length', 0) < 1);

    return {...productGroup, media};
  });

  _.remove(productGroups, productGroup => productGroup.media.length === 0);

  // lib.filterUsedMedia({items: productGroups});

  return (
    <div className='product-groups-grid'>
      {_.map(productGroups, (group, index) => (
        <GroupCell {...{group, groupMode, index}} key={`group-${group.title}`}/>
      ))}
    </div>
  );
}

class GroupCell extends Component {
  state = {
    activeMediaIndex: 0,
    mediaInterval: null
  }

  componentDidMount() {
    setTimeout(() => {
      var mediaInterval = setInterval(() => {
        var index = lib.number.cycleIndex({direction: 'right', min: 0, max: _.get(this.props.group, 'media.length') -1, input: this.state.activeMediaIndex});

        this.setState({activeMediaIndex: index});
      }, 6000);

      this.setState({mediaInterval});
    }, this.props.index % 2 ? 3000 : 0);
  }

  componentWillUnmount = () => clearInterval(this.state.mediaInterval);

  render() {
    var {props, state} = this;
    var {group, groupMode, index} = props;

    var style = {transitionDelay: `${index * 0.08}s`};

    return (
      <Link {...className(['group-cell', group.text && 'promotion-panel'])} style={style} to={`/objects/by/${groupMode}/${group.key}`}>
        <div className='group-images-container'>
          {group.media && _.map(group.media, (medium, index) => (
            <Image imageUrl={medium.url} key={index} id={index} activeMediaIndex={state.activeMediaIndex}/>
          ))}
        </div>
        <div className='group-title'>{group.title}</div>
      </Link>
    );
  }
}

var Image = ({id, activeMediaIndex, imageUrl}) => (
  <div {...className(['group-image', id === activeMediaIndex && 'active'])} style={{backgroundImage: `url(${imageUrl})`}}/>
);

import {_, React, Component, className, lib} from 'components'; //eslint-disable-line
import ProductGroupsGrid from './product-groups-grid/product-groups-grid';
import ProductsGrid from './products-grid/products-grid';
import './products-index.scss';


export default function ProductsIndex(props) {
  var {groupKey, searchTerm} = props;
  var ProductsGridView = groupKey || searchTerm ? ProductsGrid : ProductGroupsGrid;

  return (
    <div className='products-index-container'>
      <div className='products-index-content'>
        <div className='grid-container'>
          <ProductsGridView {...props}/>
        </div>
      </div>
    </div>
  );
}

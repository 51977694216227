export default [
  {title: 'primary objects home', groupKey: 'all', groupMode: 'home'},
  {title: 'objects by type', groupMode: 'type'},
  {title: 'studio group', groupKey: 'studio-group', groupMode: 'type'},
  {title: 'opencase', groupKey: 'opencase', groupMode: 'type'},
  {title: 'islands', groupKey: 'islands', groupMode: 'type'},
  {title: 'armoires & dressers', groupKey: 'armoires-dressers', groupMode: 'type'},
  {title: 'seating', groupKey: 'seating', groupMode: 'type'},
  {title: 'pantries & shelving', groupKey: 'pantries-shelving', groupMode: 'type'},
  {title: 'vanities', groupKey: 'vanities', groupMode: 'type'},
  {title: 'consoles & sidecases', groupKey: 'consoles-sidecases', groupMode: 'type'},
  {title: 'bathroom storage', groupKey: 'bathroom-storage', groupMode: 'type'},
  {title: 'tables', groupKey: 'tables', groupMode: 'type'},
  {title: 'partition', groupKey: 'partition', break: true, groupMode: 'type'},
  {title: 'all', groupKey: 'all', groupMode: 'type'},
   // {title: 'opencase accessories', groupKey: 'opencase-accessories'},
   // {title: 'interior components', groupKey: 'interior-components'},

  {title: 'objects by space', groupMode: 'space'},
  {title: 'kitchen & pantry', groupKey: 'kitchen-pantry', groupMode: 'space'},
  {title: 'bedroom', groupKey: 'bedroom', groupMode: 'space'},
  {title: 'living', groupKey: 'living', groupMode: 'space'},
  {title: 'bath', groupKey: 'bath', groupMode: 'space'},
  {title: 'dining', groupKey: 'dining', groupMode: 'space'},
  {title: 'study', groupKey: 'study', groupMode: 'space'},
  {title: 'entry', groupKey: 'entry', groupMode: 'space'},
  {title: 'mudroom', groupKey: 'mudroom', groupMode: 'space'},
  {title: 'all', groupKey: 'all', groupMode: 'space'},

  {title: 'objects by activity', groupMode: 'activity'},
  {title: 'cooking & prep', groupKey: 'cooking-prep', groupMode: 'activity'},
  {title: 'dressing', groupKey: 'dressing', groupMode: 'activity'},
  {title: 'leisure', groupKey: 'leisure', groupMode: 'activity'},
  {title: 'storage', groupKey: 'storage', groupMode: 'activity'},
  {title: 'dining', groupKey: 'dining', groupMode: 'activity'},
  {title: 'serving', groupKey: 'serving', groupMode: 'activity'},
  {title: 'vanities', groupKey: 'vanities', groupMode: 'activity'},
  {title: 'all', groupKey: 'all', groupMode: 'activity'},

  {title: 'full collection', groupKey: 'all', groupMode: 'all'},
];

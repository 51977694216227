import {_, React, Component, className, lib} from 'components'; //eslint-disable-line
import Dimensions from 'components/dimensions/dimensions';
import utilities from 'utility/utility-functions';
import './slideshow.scss';

class Slideshow extends Component {
  state = {
    isFullScreen: false,
    activeIndex: 0
  }

  componentDidMount() {
    var handleIndexChange = _.get(this.props, 'onIndexChange', this.handleIndexChange);

    document.onkeydown = (event) => {
      event = event || window.event;

      if (event.code === 'ArrowLeft') handleIndexChange({direction: 'left'});
      if (event.code === 'ArrowRight') handleIndexChange({direction: 'right'});

      if (event.code === 'Escape' && this.state.isFullScreen) this.toggleFullScreen();
    };
  }

  toggleFullScreen = () => this.setState(oldState => ({isFullScreen: !oldState.isFullScreen}));

  handleIndexChange = ({direction}) => {
    var max = _.get(this.props, `media.length`) -1;
    var index = lib.number.cycleIndex({direction, min: 0, max, input: this.state.activeIndex});

    this.setState({activeIndex: index});
  }

  render() {
    var {toggleFullScreen} = this;
    var {isFullScreen} = this.state;
    var {media=[], prohibitFullScreen, product, usePinterest} = this.props;
    var activeIndex = _.get(this.props, 'activeIndex', this.state.activeIndex);
    var handleIndexChange = _.get(this.props, 'onIndexChange', this.handleIndexChange);

    return (
      <div {...className(['slideshow', !prohibitFullScreen && isFullScreen && 'is-full-screen'])}>
        <div className='fullscreen-background'/>
        <div className='slideshow-media-container'>
          {_.map(media, (medium, index) => (
              <Medium key={medium.id} {...{medium, index, activeIndex, toggleFullScreen}}/>
            ))}
        </div>
        <SlideshowTray {...{media, activeIndex, handleIndexChange, isFullScreen, product, toggleFullScreen, usePinterest}}/>
      </div>
    );
  }
}

export default Slideshow;

var Medium = ({index, activeIndex, toggleFullScreen, medium}) => {
  var {url, type, subjectType} = medium;

  return (
    <div
      style={{backgroundImage: `url(${url})`}}
      {...className(['slideshow-medium', `type-${type}`, activeIndex === index && 'active', subjectType === 'drawing' && 'drawing'])}
      > {type === 'video' ? (
        <video className='slideshow-video-player' muted autoPlay loop playsInline preload='none'>
          <source type='video/mp4' src={url}/>
        </video>
      ) : (
        <img src={url} className='image-for-right-clicking' alt='' onClick={toggleFullScreen}/>
      )}
    </div>
  );
};

var SlideshowTray = ({media, activeIndex, handleIndexChange, isFullScreen, product, toggleFullScreen, usePinterest}) => {
  React.useEffect(() => {
    if (usePinterest) {
      var func = document.getElementsByTagName('SCRIPT')[0], parent = document.createElement('SCRIPT');
      parent.type = 'text/javascript';
      parent.async = true;
      parent.src = '//assets.pinterest.com/js/pinit.js';
      parent.key =
      func.parentNode.insertBefore(parent, func);

      if (_.get(window, 'PinUtils.build')) window.PinUtils.build();
    }
  }, [usePinterest]);

  return (
    media.length > 0 && <div className={`slideshow-tray count-${media.length}`}>
      {product && isFullScreen ? (
        <Dimensions product={product} display='inline'/>
      ) : <div/>}
      <div className='slideshow-tray-controls'>
        <div className='slideshow-index'>{`${activeIndex + 1} / ${media.length}`}</div>
        {_.map([
            {key: 'left', link: 'main/lArrow.png', onClick: () => handleIndexChange({direction: 'left'})},
            {key: 'right', link: 'main/rArrow.png', onClick: () => handleIndexChange({direction: 'right'})}
          ], ({key, link, onClick}) => (
          <div className={`slideshow-button ${key}-button-container`} {...{key, onClick}}>
            <img alt='' className={`${key}-button`} src={utilities.cdnLink(`images/${link}`)}/>
          </div>
        ))}
        {usePinterest && <div className='slideshow-button pinterest-button-container'>
          <a
            data-pin-build='doBuild'
            data-pin-id={_.get(product, 'id', 0)}
            data-pin-custom data-pin-do='buttonBookmark'
            href='https://www.pinterest.com/pin/create/button/'
          >
            <img alt='' className='pinterest-button' src='https://henrybuilt-cdn.s3-us-west-2.amazonaws.com/po/icons/pinterest.png'/>
          </a>
        </div>}
        {_.map([
            {key: 'enter', link: 'icons/enter-fullscreen.png'},
            {key: 'exit', link: 'main/close.png'},
          ], ({key, link}) => (
          <div className={`slideshow-button ${key}-button-container`} key={key} onClick={toggleFullScreen}>
            <img alt='' className={`${key}-button`} src={utilities.cdnLink(`images/${link}`)}/>
          </div>
        ))}
      </div>
    </div>
  );
};

import {_, lib, Component, React, className} from 'components'; //eslint-disable-line
import {connect} from '@henrybuilt/react-app/src';
import localStorage from 'local-storage';
import queryString from 'query-string';
import {Link} from 'react-router-dom';
import './po-header.scss';

//HINT this is a separate component so it can be connected
var POHeader = ({cart, session}) => {
  var productInstanceCount = _.sum(_.map(cart.productInstances, 'quantity'));

  var cartLinkOnClick = () => {
    var transactionToken = localStorage('transactionToken');

    if (transactionToken) {
      lib.api.request({uri: 'po-cart/cancel-order', body: {transactionToken}});
    }

    localStorage.remove('transactionToken');

    // if (window.location !== `${window.location.origin}/cart`) window.location = `${window.location.origin}/cart`;
  };

  var onClick = () => {
    var environment = process.env.REACT_APP_IN_STAGING ? 'staging' : process.env.NODE_ENV;
    var domains = lib.components.determineDomain({environment});
    var link;

    if (session.isLoggedIn) {
      var loginQueryString = queryString.stringify({redirect: `${domains.objects}/login`});

      link = `${domains.projects}/logout?${loginQueryString}`;
    }
    else {
      link = `${domains.projects}/login?to=${window.location.pathname}`;
    }

    window.location.replace(link);
  };

  return (
    <div className='po-header'>
      {session.user && <span className='user-name'>{session.user.name}</span>}
      <span className='logout-login-link' onClick={onClick}>log {session.isLoggedIn ? 'out' : 'in'}</span>
      <Link className='cart-link' to='/cart' onClick={cartLinkOnClick}>{`Cart${productInstanceCount ? ` (${productInstanceCount})` : ``}`}</Link>
    </div>
  );
};

POHeader = connect({mapState: ['cart', 'session']})(POHeader);

export default POHeader;

import React from 'react';
import _ from 'lodash';
import {Component, className} from 'henrybuilt-react-library';

//TODO remove
if (!window.lib) require('henrybuilt-js-library');

var lib = window.lib;

export {_, React, Component, className, lib};

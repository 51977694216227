import {reducerAndActionsFor} from 'reducers/helpers';
import localStorage from 'local-storage';
import _ from 'lodash';

var saveCart = ({cart}) => {
  var stringifiedCart = JSON.stringify(cart);

  localStorage.remove('transactionToken');

  localStorage.set('cart', stringifiedCart);
};

var {reducer: cartReducer, actions} = reducerAndActionsFor('cart', {
  updateCartProductInstance: ({productInstance, getState}) => {
    var {cart} = getState();
    var {productInstances} = cart;

    cart.productInstances = {...productInstances, [productInstance.uuid]: productInstance};

    saveCart({cart});

    return cart;
  },
  removeCartProductInstance: ({productInstance, getState}) => {
    var {cart} = getState();
    var {productInstances} = cart;

    cart.productInstances = _.omit(productInstances, productInstance.uuid);

    saveCart({cart});

    return cart;
  },
  resetCartOnCheckout: ({getState}) => {
    var {cart} = getState();

    cart.productInstances = {};

    saveCart({cart});

    //this happens in saveCart
    //being redundant so unintentional saveCart changes don't break this
    localStorage.remove('transactionToken');

    return cart;
  },
  updateDeliveryAddress: ({deliveryAddress, getState}) => {
    var {cart} = getState();
    var {deliveryAddress: oldDeliveryAddress} = cart;

    cart.deliveryAddress = _.assign(oldDeliveryAddress, deliveryAddress);

    saveCart({cart});

    return cart;
  },
  removeDeliveryAddress: ({getState}) => {
    var {cart} = getState();

    cart.deliveryAddress = null;

    saveCart({cart});

    return {};
  },
  updateContactInfo: ({contactInfo, getState}) => {
    var {cart} = getState();
    var {contactInfo: oldContactInfo} = cart;

    cart.contactInfo = _.assign(oldContactInfo, contactInfo);

    saveCart({cart});

    return cart;
  },
  removeContactInfo: ({getState}) => {
    var {cart} = getState();

    cart.contactInfo = null;

    saveCart({cart});

    return {};
  }
});

var {updateCartProductInstance, removeCartProductInstance, updateDeliveryAddress, removeDeliveryAddress, updateContactInfo, removeContactInfo, resetCartOnCheckout} = actions;

export default cartReducer;
export {updateCartProductInstance, removeCartProductInstance, updateDeliveryAddress, removeDeliveryAddress, updateContactInfo, removeContactInfo, resetCartOnCheckout};

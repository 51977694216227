import {_, React, Component, className, lib} from 'components'; //eslint-disable-line
import {Form} from 'henrybuilt-react-library';
import './search-bar.scss';

var SearchBar = ({id, searchTerm, handleSearchInput, screen}) => (
  !id && <div {...className(['search-bar', screen])}>
    <Form method='post'>
      <Form.TextInput value={searchTerm} placeholder='search site...' on={{input: handleSearchInput}}/>
    </Form>
  </div>
);

export default SearchBar;

import {React, Component} from 'components';
import {Form} from 'henrybuilt-react-library';
import './subscribe-form.scss';

export default class SubscribeForm extends Component {
  render() {
    return (
      <div className='subscribe-form'>
        <Form action='https://www.weflowapp.com/w2e/post' method='post'>
          <Form.TextInput name={5995} id={5995} placeholder={'email'} isRequired={true}/>
          <Form.SubmitButton submitText='→'/>
          <Form.HiddenInput name={6328} id={6328} value={5}/> {/* Subscribed To: HB/PO */}
          <Form.HiddenInput name={10150} id={10150} value={2}/> {/* Source: Po.com */}
          <Form.HiddenInput name={12864} id={12864} value={5}/> {/* Subscription Type: Projects */}
          <Form.HiddenInput name='w2e_id' id='w2e_id' value={137}/>
          <Form.HiddenInput name='section_id' id='section_id' value={646}/>
          <Form.HiddenInput name='grp_id' id='grp_id' value={1}/>
        </Form>
      </div>
    );
  }
}

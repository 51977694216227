import {_, lib, Component, React, className} from 'components'; //eslint-disable-line
import {BrowserRouter as Router, Route, Switch, withRouter} from 'react-router-dom';
import Cookies from 'js-cookie';
import './app.scss';

import ProductsPage from 'components/pages/products/products-page';
import AboutPage from 'components/pages/about/about-page';
import {TradeRegistryPage, ResetPasswordPage} from 'components/pages/auth/auth-pages';
// import TradeRegistryWelcomePage from 'components/pages/auth/trade-registry-welcome/trade-registry-welcome-page';
import CartPage from 'components/pages/cart/cart-page';
import PageNotFound from 'components/pages/page-not-found/page-not-found-page';
// import InTheWorksPage from 'components/pages/in-the-works/in-the-works-page';
import SubscribePopup from 'components/subscribe-popup/subscribe-popup';
import SubscribeForm from 'components/subscribe-form/subscribe-form';
import 'styles/app-styles.scss';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      subscribePopupIsVisible: false,
      subscribeFormHasReloaded: false
    };

    this.bind(['considerShowingSubscribePopup', 'handleSubscribePopupClose']);

    var environment = process.env.REACT_APP_IN_STAGING ? 'staging' : process.env.NODE_ENV;

    if (environment === 'development') {
      this.domain = 'localhost';
    }
    else {
      this.domain = lib.components.determineDomain({environment})['henrybuilt'].replace(/(^\w+:|^)\/\//, '');
    }

    this.ProductsPage = (props) => <ProductsPage considerShowingSubscribePopup={this.considerShowingSubscribePopup} {...props}/>;

    this.App = () => (
      <div className='app'>
        <div className='header' environment={process.env.REACT_APP_IN_STAGING ? 'staging' : process.env.NODE_ENV}/>
        <div className='content'>
          <Router>
            <Switch>
              <Route
                path={['/', '/objects', '/objects/by/:groupMode', '/objects/by/:groupMode/:groupKey', '/objects/:id/:title?', '/objects/by/:groupMode/:groupKey/:id/:title?']}
                exact
                component={this.ProductsPage}
              />
              <Route path='/reset-password/:resetPasswordToken' exact component={ResetPasswordPage}/>
              <Route path='/trade-registry' exact component={TradeRegistryPage}/>
              <Route path='/about' exact component={AboutPage}/>
              <Route path='/cart' exact component={CartPage}/>
              <Route component={PageNotFound}/>
            </Switch>
          </Router>
        </div>
        {this.state.subscribePopupIsVisible && (
          <SubscribePopup
            handleSubscribePopupClose={this.handleSubscribePopupClose}
            handleSubmitSubscribePopupSubmit={this.handleSubmitSubscribePopupSubmit}
          />
        )}
        <div className='footer'/>
      </div>
    );
  }

  considerShowingSubscribePopup({pathname}) {
    var subscribePopupCookie = Cookies.getJSON('hb_subscribe-popup', {domain: this.domain});

    if (subscribePopupCookie) {
      var pagesVisited = _.get(subscribePopupCookie, 'pagesVisited', []);
      var userAction = _.get(subscribePopupCookie, 'userAction');

      if (pagesVisited.length < 2 && !_.includes(pagesVisited, pathname)) {
        pagesVisited = [pathname ,...pagesVisited];

        Cookies.set('hb_subscribe-popup', {pagesVisited, userAction}, {expires: 30, domain: this.domain});
      }

      var hasViewedEnoughPages = pagesVisited.length >= 2;
      var hasNotClosedPopup = typeof userAction === 'undefined';

      if (hasViewedEnoughPages && hasNotClosedPopup) {
        this.setState({subscribePopupIsVisible: true});
      }
    }
    else {
      Cookies.set('hb_subscribe-popup', {pagesVisited: [pathname], userAction}, {expires: 30, domain: this.domain});
    }
  }

  handleSubmitSubscribePopupSubmit = () => {
    this.setState({subscribePopupIsVisible: false});

    var {pagesVisited} = Cookies.getJSON('hb_subscribe-popup', {domain: this.domain});

    Cookies.set('hb_subscribe-popup', {userAction: 'submit', pagesVisited}, {expires: 30, domain: this.domain});
  }

  handleSubscribePopupClose() {
    this.setState({subscribePopupIsVisible: false});

    var {pagesVisited} = Cookies.getJSON('hb_subscribe-popup', {domain: this.domain});

    Cookies.set('hb_subscribe-popup', {userAction: 'close', pagesVisited}, {expires: 30, domain: this.domain});
  }

  render() {
    return (
      <Switch>
        <Route path='/subscribe-form' component={SubscribeForm} />
        <Route component={this.App}/>
      </Switch>
    );
  }
}

export default withRouter(App);
export {App};

import {_, React, Component, className, lib} from 'components'; //eslint-disable-line
import POHeader from 'components/po-header/po-header';
import utilities from 'utility/utility-functions';
import SearchBar from 'components/search-bar/search-bar';
import queryString from 'query-string';
import {Link} from 'react-router-dom';
import './products-header.scss';

export default function ProductsHeader(props) {
  var {product} = props;
  var Header = product ? ShowHeader : IndexHeader;

  var cameFrom = queryString.parse(window.location.search).cameFrom;

  return (
    <div className='products-header'>
      <POHeader/>
      <Header {...{cameFrom, ...props}}/>
    </div>
  );
}

class ShowHeader extends Component {
  state = {loaded: false}

  componentDidMount() {
    setTimeout(() => this.setState({loaded: true}), 500);
  }

  render() {
    var {product, groupTitle, groupMode, groupKey, searchTerm, cameFrom} = this.props;
    var backUri = utilities.uriForNavigation({groupMode, groupKey, searchTerm});
    var {loaded} = this.state;

    return (
      <div className='show-header'>
        <div className='show-title-container'>
          <div className='show-title'>{product.title}</div>
          <BackArrow {...{cameFrom, loaded, to: backUri, screen: 'mobile'}}/>
        </div>
        {<div className='sibling-products-container'>
          <BackArrow {...{cameFrom, loaded, to: backUri, screen: 'desktop'}}/>
          {product.siblingProducts.length > 1 && _.map(_.sortBy(product.siblingProducts, 'rank'), (siblingProduct, index) => {
            var {id, title} = siblingProduct;

            title = _.replace(title, `${groupTitle} -`, '');

            if (title !== groupTitle && !_.includes([1101, 1155], id)) title = _.trim(_.replace(title, groupTitle, ''));

            var uri = utilities.uriForNavigation({groupMode, groupKey, id, title: siblingProduct.title, searchTerm});

            var style = {transitionDelay: `${(index + 1) * 0.50}s`};

            return (
              <Link {...className(['sibling-product', id === product.id && 'active', !loaded && 'hidden'])} to={uri} key={id} style={style}>
                {title}
              </Link>
            );
          })}
        </div>}
      </div>
    );
  }
}

var IndexHeader = ({searchTerm, productGroups, id, groupKey, groupMode, groupTitle, cameFrom, handleSearchInput}) => (
  <div className='index-header'>
    <div {...className(['index-header-title', searchTerm && 'hidden'])}>
      {((groupKey && groupKey !== 'all') || cameFrom) && <BackArrow {...{cameFrom}} to={`/objects/by/${groupMode}`}/>}
      {groupKey ? <div {...className(['group-title', productGroups && !searchTerm && 'hidden'])}>{groupTitle}</div>
        :
      <div className='henrybuilt'><span>Primary Objects</span><span> Collection</span></div>}
    </div>
    <SearchBar {...{id, searchTerm, handleSearchInput, screen: 'mobile'}}/>
  </div>
);


var BackArrow = ({cameFrom, loaded, to, screen}) => {
  var BackLink = (props) => (
    <div {...className(['back-link', !loaded && 'hidden', screen, cameFrom])} onClick={() => window.history.back()}>{props.children}</div>
  );

  var LinkType = cameFrom ? BackLink : Link;

  return (
    <LinkType to={to} {...className(['back-link', screen, !loaded && 'hidden'])}>
      <img
        alt='submit'
        className='back-arrow'
        src={utilities.leftArrowUrl}
      />
    </LinkType>
  );
};

import {_, React, Component, className, lib} from 'components'; //eslint-disable-line
import SearchBar from 'components/search-bar/search-bar';
import {Link} from 'react-router-dom';
import items from './sidebar-data';
import './sidebar.scss';

class Sidebar extends Component {
  state = {activeTabMode: 'type'}

  componentDidMount() {
    this.setState({activeTabMode: this.props.groupMode});
  }

  render() {
    var {groupMode, groupKey, id, searchTerm, handleSearchInput, pageLoaded} = this.props;
    var {activeTabMode} = this.state;

    return (
      <div className='sidebar'>
        <div className='sidebar-content'>
          {_.map(items, (item) => {
            var SidebarItem;

            if (!item.groupKey) {
              SidebarItem = (
                <div
                  key={`${item.groupMode}-${item.title}`}
                  onClick={() => this.setState({activeTabMode: item.groupMode})}
                  {...className(['sidebar-button', 'sidebar-item', item.groupMode === groupMode && 'active'])}
                >
                  {item.title}
                </div>
              );
            }
            else if (item.groupMode === 'home') {
              SidebarItem = (
                <Link
                  key={`${item.groupMode}-${item.title}`}
                  to={`/objects/by/type`}
                  onClick={() => {
                    if (window.location.pathname === '/objects/by/type') window.location.reload();
                  }}
                  {...className(['sidebar-button', 'sidebar-item'])}
                >
                  {item.title}
                </Link>
              );
            }
            else if (item.groupMode === 'all') {
              SidebarItem = (
                <Link
                  key={`${item.groupMode}-${item.title}`}
                  to={`/objects/by/all`}
                  {...className(['sidebar-button', 'sidebar-item', groupMode === 'all' && 'active'])}
                >
                  {item.title}
                </Link>
              );
            }
            else if (activeTabMode === item.groupMode) {
              // link is highlighted if the link matches the current page's groupMode/Key (no groupKey activates "all")
              var active = item.groupMode === groupMode && (item.groupKey === groupKey || (item.groupKey === 'all' && !groupKey));

              SidebarItem = (
                <Link
                  key={`${item.groupMode}-${item.title}`}
                  to={`/objects/by/${item.groupMode}/${item.groupKey === 'all' ? '' : item.groupKey}`}
                  {...className(['sidebar-link', 'sidebar-item', active && 'active'])}
                >
                  {item.title}
                </Link>
              );
            }

            return SidebarItem;
          })}
          {pageLoaded && <SearchBar {...{id, searchTerm, handleSearchInput, screen: 'desktop'}}/>}
        </div>
      </div>
    );
  }
}

export default Sidebar;

import {_, React, Component, className, lib} from 'components'; //eslint-disable-line
import Slideshow from 'components/slideshow/slideshow';
import Details from './details/details';
// import DocumentMeta from 'react-document-meta';
import './products-show.scss';
import CartProductInstancePopup from 'components/cart-product-instance-popup/cart-product-instance-popup';

class ProductsShow extends Component {
  state = {
    isAddingToCart: false
  }

  addToCart = () => {
    this.setState({isAddingToCart: true});
  }

  doneAddingToCart = () => {
    this.setState({isAddingToCart: false});
  }

  render() {
    var {product, productPageView: view, switchProductPageView: changeView, history} = this.props;
    var otherView = view === 'description' ? 'details' : 'description';
    var LeftView = view === 'description' ? Description : Details;
    var otherViewButtonText = otherView === 'description' ? 'back to object' : otherView;

    var environment = process.env.REACT_APP_IN_STAGING ? 'staging' : process.env.NODE_ENV;
    var {projects: projectsDomain} = lib.components.determineDomain({environment});

    return (
      <div className='products-show'>
        {this.state.isAddingToCart && (
          <CartProductInstancePopup onClose={this.doneAddingToCart} {...{product, history}}/>
        )}
        <div className='show-content'>
          <div className='left-content'>
            <div className='product-display'>
              <LeftView product={product}/>
            </div>
            <div className='buttons'>
              <div className={`${otherView}-button`} onClick={changeView}>{otherViewButtonText}</div>
              <div className='add-to-cart-button' onClick={this.addToCart}>purchase</div>
              <a className='inquire-button' href={`${projectsDomain}/page/contact?product_id=${product.id}#form`}>contact us</a>
          </div>
        </div>
          <div className='slideshow-container'>
            <Slideshow
              product={product}
              usePinterest={true}
              key={`${product.id}-${view}`}
              media={view === 'description' ? _.orderBy(product.images, [({type}) => type === 'video'], ['desc']) : product.drawings}
            />
          </div>
        </div>
      </div>
    );
  }
}

      // <DocumentMeta {...meta}/>

export default ProductsShow;

var Description = ({product}) => {
  var {description, componentsDescription, price, tradePrice} = product;

  return (
    <div className='description-view'>
      <div className='main-description'>{description}</div>
      {componentsDescription ? <div className='components-description'>{componentsDescription}</div> : null}
      {price ? <div className='price-container'>
        <div {...className(['price', tradePrice && 'crossed-out'])}>{price}</div>
        {tradePrice && <div className='price'>{tradePrice}<div className='trade-price-text'> (trade & client price)</div></div>}
      </div> : null}
    </div>
  );
};

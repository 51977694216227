import {_, React, Component, className} from 'components'; //eslint-disable-line
import { Popup } from 'henrybuilt-react-library';
import './subscribe-popup.scss';

class SubscribePopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      subscribeFormHasReloaded: false
    };

    this.bind(['handleSubscribeFormLoad']);
  }

  handleSubscribeFormLoad() {
    if (this.state.subscribeFormHasReloaded) { // HINT iframe reloads after submit
      this.setTimeout(() => this.props.handleSubmitSubscribePopupSubmit(), 2000);
    }
    else {
      this.setState({subscribeFormHasReloaded: true});
    }
  }

  render() {
    return (
      <Popup type='fitted' onClose={this.props.handleSubscribePopupClose}>
        <div className='subscribe-popup'>
          <div className='subscribe-popup-tagline'>Thank you for visiting us.</div>
          <div className='subscribe-popup-message'>Subscribe here to stay updated on new project stories and product news.</div>
          <div className='subscribe-form-container'>
            <iframe title='subscribe-form-iframe' src='/subscribe-form' onLoad={this.handleSubscribeFormLoad}></iframe>
          </div>
        </div>
      </Popup>
    );
  }
}

export default SubscribePopup;

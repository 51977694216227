import {_, React, Component, className, lib} from 'components'; //eslint-disable-line
import './dimensions.scss';

export default function Dimensions({product, display}) {
  var dimensionDataSets = lib.dimensions.dimensionDataSetsFor({product});
  var notes = _.get(product.additionalConstraints, 'notes', '');

  return (
    <div {...className(['dimensions-container', `display-${display}`])}>
     {_.map(dimensionDataSets, ({dimensionsData, title}, index) => (
       <div className='dimensions-group' key={index}>
         {title ? <div className='dimensions-group-title'>{title}</div> : <div/>}
         {_.map(_.reject(dimensionsData, {key: 'standardSize'}), ({title, constraintDescription, key}) => (
           constraintDescription && <div className={`${key}-dimension dimension`} key={key}>
             <div className='dimension-title'>{title}</div>
             <div className='dimension-text'>{constraintDescription}</div>
           </div>
         ))}
       </div>
      ))}
      {notes && <div className='dimension-notes'>{notes}</div>}
    </div>
  );
}

import {_, lib} from 'components';  //eslint-disable-line
import moment from 'moment';

var defaultMediaParams = {where: {public: 1}, fields: ['id', 'url', 'type', 'subjectType', 'rank'], order: [{field: 'rank', direction: 'desc'}]};

var defaultIndexProductParams = {
  order: ['rank', 'id'], fields: ['id', 'title', 'rank', 'categoryId'], where: {isPrimaryObject: 1, isSellable: 1}
};

var defaultShowProductParams = {
  ...defaultIndexProductParams,
  fields: [
    'id', 'title', 'rank', 'description', 'componentsDescription', 'constraints', 'additionalConstraints', 'installationData', 'installationText',
     'pricingRuleId', 'categoryId', 'materialAssociations', 'associations', 'keywords', 'doNotFactor', 'insideDeliveryCategory'
   ]
};

var utilities = {
  leftArrowUrl: 'https://henrybuilt-cdn.s3-us-west-2.amazonaws.com/images/icons/footer-arrows/arrow-left.png',
  rightArrowUrl: 'https://henrybuilt-cdn.s3-us-west-2.amazonaws.com/images/icons/footer-arrows/arrow-right.png',
  cdnLink: suffix => `https://s3-us-west-2.amazonaws.com/henrybuilt-cdn/${suffix}`,
  uploadedFile: suffix => `https://s3-us-west-2.amazonaws.com/henrybuilt-uploaded-files/${suffix}`,

  defaultMediaParams,
  defaultIndexProductParams,
  defaultShowProductParams,

  filterUsedMedia: ({items}) => {
    var usedMediaIds = {};

    _.forEach(items, (item) => {
      var unusedMedia = [], usedMedia = [];

      _.forEach(item.media, medium => {
        if (usedMediaIds[medium.id]) {
          usedMedia.push(medium);
        }
        else {
          if (_.isEmpty(unusedMedia)) usedMediaIds[medium.id] = true;

          unusedMedia.push(medium);
        }

        item.media = [...unusedMedia, ...usedMedia];
      });
    });
  },

  priceForAmounts: ({to, from, multiplier=1}) => (
    from.getAmount() === to.getAmount() ? from.multiply(multiplier).toFormat('$0,0') : `${from.multiply(multiplier).toFormat('$0,0')} - ${to.multiply(multiplier).toFormat('$0,0')}`
  ),

  orderByIds: ({array=[], ids=[]}) => {
    var order = {};
    _.forEach(ids, (id, index) => order[id] = index);

    return _.sortBy(array, item => order[_.get(item, 'id')]);
  },

  uriForNavigation: ({groupKey, groupMode, searchTerm, id, title}) => {
    var uri = `/objects/`;

    if (groupMode === 'all') {
      uri += `by/all/all/`;
    }
    else if (groupKey) {
      uri += `by/${groupMode}/${groupKey}/`;
    }

    if (id && title) {
      var productSlug = _.kebabCase(title.replace(/[^a-zA-Z0-9 ]/g, ''));

      uri += `${id}/${productSlug}`;
    }

    if (searchTerm) uri += `?searchTerm=${searchTerm}`;

    return uri;
  },

  async getFullProductsData({ids, groupMode, groupKey, canViewTradePrice}) {
    var result = {};
    var groupTitle;

    var products = await lib.api.get('products', {
      ..._.pick(defaultShowProductParams, ['fields', 'order']),
      where: {id: ids, isPrimaryObject: 1, isSellable: 1},
      include: {
        materials: {where: {salesOnly: 0, isDiscontinued: 0, isEmployeeOnly: 0}},
        productTags: {},
        media: defaultMediaParams,
        documents: {where: {isPublic: 1}},
        pulls: {include: {media: defaultMediaParams}},
        pricingRule: {include: {pricingRuleVersions: {}}},
        productDetails: {include: {productDetailOptions: {
          include: {media: _.defaultsDeep({fields: ['id', 'url', 'type', 'subjectType', 'rank', 'associations']}, defaultMediaParams)}}}
        }
      }
    });

    if (products.length === 1) {
      var {materialClasses, bottomLevelProductCategory, products: siblingProducts} = await lib.api.get({
        materialClasses: {
          where: {id: _.map({...products[0].materialAssociations, ...products[0].pulls}, 'materialClassId')},
          include: {materials: {where: {salesOnly: 0, isDiscontinued: 0, isEmployeeOnly: 0}, include: {media: _.defaultsDeep({where: {rank: 1}}, defaultMediaParams)}}}
        },
        bottomLevelProductCategory: {where: {id: products[0].categoryId}},
        products: {
          where: {categoryId: products[0].categoryId, isPrimaryObject: 1, isSellable: 1},
          ..._.pick(defaultIndexProductParams, ['fields', 'order'])
        }
      });

      if (_.get(products[0], 'productTags.length') > 1 && _.includes(['space', 'activity'], groupMode) && groupKey) {
        products[0].media =  await lib.api.get('media', _.defaultsDeep({where: {productId: products[0].id, productTagKey: groupKey}}, defaultMediaParams));
      }
    }
    else if (products.length > 1) {
      var materialClassIds = _.chain(products)
                              .map(product => _.map({...product.materialAssociations, ...product.pulls}, 'materialClassId'))
                              .flatten()
                              .uniq()
                              .value();

      materialClasses = await lib.api.get('materialClasses', {
        where: {id: materialClassIds},
        include: {materials: {where: {salesOnly: 0, isDiscontinued: 0, isEmployeeOnly: 0}, include: {media: _.defaultsDeep({where: {rank: 1}}, defaultMediaParams)}}}
      });
    }

    _.forEach(materialClasses, materialClass => {
      _.forEach(materialClass.materials, material => {
        material.iconMedium = _.filter(material.media, {subjectType: 'icon'})[0];

        if (products.length === 1) material.media = _.filter(material.media, {subjectType: 'standard'});
      });
    });

    _.forEach(products, product => {
      product.materialClasses = _.filter(materialClasses, materialClass => (
        _.includes(_.map(product.materialAssociations, 'materialClassId'), materialClass.id)
      ));

      _.forEach(product.pulls, pull => pull.materialClass = _.find(materialClasses, {id: pull.materialClassId}));
    });

    _.forEach(products, async product => {
      product.materialAssociations = _.map(product.materialAssociations, (materialAssociation, key) => ({...materialAssociation, key}));
      product.materialAssociations = _.reject(product.materialAssociations, {materialClassId: 1});

      _.forEach(product.materialAssociations, materialAssociation => {
        materialAssociation.materials = _.get(_.find(product.materialClasses, {id: materialAssociation.materialClassId}), 'materials');
      });

      _.forEach(product.pulls, pull => {
        pull.iconMedium = _.filter(pull.media, {subjectType: 'icon'})[0];
        pull.media = _.filter(pull.media, {subjectType: 'standard'});
        pull.key = pull.id;
      });

      _.forEach(product.productDetails, productDetail => {
        _.forEach(productDetail.productDetailOptions, productDetailOption => {
          productDetailOption.iconMedium = _.find(productDetailOption.media, medium => (
            medium.subjectType === 'icon' && medium.associations.products[`id_${product.id}`]
          ));

          productDetailOption.media = _.filter(productDetailOption.media, medium => medium.associations.products[`id_${product.id}`]);
        });
      });

      //pricing
      product.dnf = product.doNotFactor;

      var {expressions} = _.last(_.get(product, 'pricingRule.pricingRuleVersions'));

      var {from, to} = lib.price.priceRangeForProduct({product, expressions, materialClasses: product.materialClasses}); //WARNING do not pass project - it will factor freight fee in
      var price = utilities.priceForAmounts({to, from, multiplier: 1});

      var tradePrice = canViewTradePrice ? utilities.priceForAmounts({to, from, multiplier: 0.85}) : null;

      _.assign(product, {
        price, tradePrice, siblingProducts,
        images: _.filter(product.media, {subjectType: 'standard'}),
        drawings: _.filter(product.media, {subjectType: 'drawing'})
      });
    });

    if (bottomLevelProductCategory) groupTitle = bottomLevelProductCategory.title;

    result = {products, groupTitle};

    return result;
  },
  formatProductsForPricing: ({products, productInstances}) => {
    var materialClasses = _.flatMap(products, 'materialClasses');

    _.forEach(products, product => {
      product.pricingRule = product.pricingRule.expressions ? product.pricingRule : _.last(product.pricingRule.pricingRuleVersions);

      product.pricingRuleId = product.pricingRule.id;
    });

    //NOTE format productInstance for js lib
    _.forEach(productInstances, productInstance => {
      productInstance.materialIds = _.map(productInstance.materials, (material, index) => {
        return {...material, key: index};
      });
    });

    var pricingRules = _.map(products, 'pricingRule');

    return {products, productInstances, materialClasses, pricingRules};
  },
  priceForProductInstance: ({productInstance, product, user}) => {
    var price;

    var {products, productInstances, materialClasses, pricingRules} = utilities.formatProductsForPricing({products: [product], productInstances: [productInstance]}); //eslint-disable-line

    var project = {
      companyKey: 'hb',
      brandKey: 'po',
      effectivePricingDate: moment()
    };

    price = lib.price.dataForProductInstance(productInstances[0], {pricingRules, products, materialClasses, project}).total;

    if (_.get(user, 'id') !== undefined) price = price.multiply(0.85);

    return price.toFormat('$0,0');
  }
};

var {rightArrowUrl} = utilities;

export default utilities;
export {rightArrowUrl};

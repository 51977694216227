import { _, React, Component, className, lib } from 'components'; //eslint-disable-line
import { Link } from 'react-router-dom';
import { Form } from 'henrybuilt-react-library';
import './auth-pages.scss';
import { connect, logIn, getCookieDomain } from '@henrybuilt/react-app/src';
import Cookies from 'js-cookie';
import { rightArrowUrl } from 'utility/utility-functions';

var locationOptions = _.omit([...lib.formData.states, 'Outside the U.S.', {value: 'CA-NORTH', title: 'California (Northern)'}, {value: 'CA-SOUTH', title: 'California (Southern)'}], 'CA');

var FormTemplate = ({action, title, fieldKeys, updateInfo, handleSubmit, isSubmitting, formValues={}}) => ( // handleKeyDown
  <div className='form-container' onSubmit={() => handleSubmit({action})}>
    <div class='trade-site-title'>
      <span>Open &nbsp;Door</span>
      <span className='wide-tagline'>: &nbsp; Henrybuilt &nbsp;Private &nbsp;Trade &nbsp;Site</span>
      <span className='narrow-tagline'> HB &nbsp;Private &nbsp;Trade &nbsp;Site</span>
    </div>
    <Form>
      <div className='form-title'>{title}</div>
      {action === 'register' && (
        <div className='trade-registry-message'>
          For our Architect, Designer, and Contractor Partners
          <br /><br />
          Open Door is a straight-shooting Q&A search-driven site built to answer your questions and provide the information you need to put our three brands to work for you in flexible ways.
        </div>
      )}
      {action === 'register' && (<a className='form-link' href='https://henrybuilt.com/login?to=trade-registry'>I already have an account</a>)}
      {action === 'login' && (<Link className='form-link' to='/trade-registry'>I need an account</Link>)}
      {_.map(fieldKeys, (fieldKey, index) => (
        <div className='form-category-container' key={index}>
          <Form.TextInput
            isPassword={_.includes(['password', 'passwordConfirmation'], fieldKey)}
            placeholder={_.startCase(fieldKey)}
            on={{input: ({value}) => updateInfo({category: fieldKey, value})}} // keyDown: ({event}) => handleKeyDown({event, action})
          />
          {fieldKey === 'email' && <div></div>}
        </div>
      ))}
      {action === 'register' && <Form.DropdownInput
        autofill='state'
        name='state'
        className='state-input'
        placeholder='select state...'
        value={formValues.state}
        options={_.map(locationOptions, location => ({title: location, value: location}))}
        on={{change: ({value}) => updateInfo({category: 'state', value})}}
      />}
      {action === 'register' && (
        <div class='i-agree'>
          <input type='checkbox' required/>
          <span>I agree to the <a href='https://henrybuilt-cdn.s3-us-west-2.amazonaws.com/files/legal/henrybuilt/privacy.pdf' target='_blank' rel='noopener noreferrer'>privacy policy</a></span>
        </div>
      )}
      <button type='submit' style={isSubmitting ? {opacity: 0.5, pointerEvents: 'none'} : {}}>
        <img alt='submit' className='submit-button' src={rightArrowUrl}/>
      </button>
    </Form>
  </div>
);

class AuthPage extends Component {
  state = {
    isSubmitting: false,
    registrationType: null,
    errors: null, // []
  }

  // handleKeyDown = ({event, action}) => {
  //   var {keyCode, which} = event;
  //
  //   if (keyCode === 13 || which === 13) this.handleSubmit({action});
  // }

  updateInfo = ({category, value}) => this.setState(oldState => ({formValues: {...oldState.formValues, [category]: value}}));

  handleSubmit = async ({action}) => {
    this.setState({isSubmitting: true});

    var actionRouteMap = {
      resetPassword: 'auth/reset-password',
      login: 'auth/token',
      register: 'trade-registry/register'
    };

    try {
      var response = await lib.api.request({
        uri: actionRouteMap[action],
        useActualErrorMessages: true,
        body: {...this.state.formValues, resetPasswordToken: this.resetPasswordToken}
      });

      if (_.includes(['login', 'resetPassword'], action) || (action === 'register' && response.data.registrationType === 'approvedProspect')) {
        //if completed registration
        var token = response.data.token;

        Cookies.set('x-domain-api-token', token, {domain: getCookieDomain()});

        await this.props.logIn({token});
      }

      if (action === 'register') {
        this.setState({registrationType: response.data.registrationType});
      }

      window.scrollTo(0, 0);
    }
    catch (error) {
      console.error(error); //eslint-disable-line
    }

    setTimeout(() => this.setState({isSubmitting: false}), 1500);
  }

  get formTemplateProps() {
    return {
      ..._.pick(this, ['updateInfo', 'handleSubmit', 'handleKeyDown', 'verifyEmail']),
      ..._.pick(this.state, ['isSubmitting', 'formValues'])
    };
  }

  render(children) {
    var {errors} = this.state;
    var {isLoggedIn, user} = this.props.session;
    var isRedirecting = isLoggedIn && user.isTrade;

    if (isRedirecting) window.location.replace('https://opendoor.henrybuilt.com');

    return (isRedirecting ? null : (
      <div {...className(['login-page'])}>
        <div className='blinking-dot'></div>
        <div className='login-forms-container'>
          {children}
        </div>
        {errors && (
          <div className='error-messages-container'>
            {_.map(errors, ({message}) => (<div className='error-messages-container'>{message}</div>))}
          </div>
        )}
      </div>
    ));
  }
}

class ResetPasswordPage extends AuthPage {
  get resetPasswordToken() {
    return _.get(this.props, `match.params.resetPasswordToken`);
  }

  render() {
    return super.render(
      <div className='reset-password-form'>
        <FormTemplate
          action='resetPassword'
          title='set password & log in'
          fieldKeys={['email', 'password', 'passwordConfirmation']}
          {...this.formTemplateProps}/>
      </div>
    );
  }
}

class TradeRegistryPage extends AuthPage {
  render() {
    return super.render(
      <div {...className(['trade-registry-form', _.includes(['unapproved', 'approvedAccount'], this.state.registrationType) && 'did-register'])}>
        <FormTemplate
          action='register'
          title='register'
          fieldKeys={['name', 'email', 'password', 'passwordConfirmation', 'company', 'phone', 'website']}
          {...this.formTemplateProps}/>
        <div className='did-register-message'>
          Thank you for your interest in Henrybuilt.
          <br /><br />
          {this.state.registrationType === 'unapproved' ?
            <>We've received your trade registration and will contact you within 2 business days to confirm acceptance. If you need to talk with us and finalize this sooner than that, please email us at <a href='mailto:traderegistry@henrybuilt.com'>traderegistry@henrybuilt.com</a> and we will do our best to contact you today or early tomorrow.</>
            :
            <>It looks like you already have an account, so we've emailed you a confirmation link. Once you click it, you'll be automatically confirmed and taken to the trade site. If you need assistance, please email us at <a href='mailto:traderegistry@henrybuilt.com'>traderegistry@henrybuilt.com</a> and we will do our best to contact you today or early tomorrow.</>
          }
          <br /><br />
          Have a good day, and we look forward to working with you.
        </div>
      </div>
    );
  }
}

class LoginPage extends AuthPage {
  render() {
    return super.render(
      <div {...className(['login-form'])}>
        <FormTemplate
          action='login'
          title='log in'
          fieldKeys={['email', 'password']}
          {...this.formTemplateProps}/>
      </div>
    );
  }
}

TradeRegistryPage = connect({mapState: ['session'], mapDispatch: {logIn}})(TradeRegistryPage);
LoginPage = connect({mapState: ['session'], mapDispatch: {logIn}})(LoginPage);
ResetPasswordPage = connect({mapState: ['session'], mapDispatch: {logIn}})(ResetPasswordPage);

export {TradeRegistryPage, LoginPage, ResetPasswordPage};

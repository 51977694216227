import {_, React, Component, className, lib} from 'components'; //eslint-disable-line
import utilities from 'utility/utility-functions';
import {Link} from 'react-router-dom';
import './products-grid.scss';

export default function ProductsGrid({productGroups, groupKey, groupMode, searchTerm}) {
  _.remove(productGroups, productGroup => productGroup.media.length === 0);

  //WARNING orderByIds is being used on collections of more than 4 items,
  //WARNING but we only care about the order of the first 4 items, so we're only specifying those first 4 ids
  if (!searchTerm) {
    if (groupKey === 'entry') {
      _.remove(productGroups, ({title}) => title === 'color box bar');
    }
    else if (groupKey === 'serving') {
      _.remove(productGroups, ({title}) => title === 'color box square' || title === 'color box vertical');

      productGroups = utilities.orderByIds({array: productGroups, ids: [1127, 125, 1125, 127]});
    }
    else if (groupKey === 'cooking-prep') {
      productGroups = utilities.orderByIds({array: productGroups, ids: [175, 1127, 1128, 1101]});
    }
    else if (groupMode === 'activity' && groupKey === 'dining') {
      productGroups = utilities.orderByIds({array: productGroups, ids: [169, 146, 147, 150]});
    }
    else if (groupKey === 'storage') {
      productGroups = utilities.orderByIds({array: productGroups, ids: [173, 850, 125, 1127]});
    }
    else if (groupKey === 'kitchen-pantry') {
      productGroups = utilities.orderByIds({array: productGroups, ids: [175, 1127, 83, 1125]});
    }
    else if (groupMode === 'space' && groupKey === 'dining') {
      productGroups = utilities.orderByIds({array: productGroups, ids: [125, 149, 146, 1113]});
    }
    else if (groupKey === 'living') {
      productGroups = utilities.orderByIds({array: productGroups, ids: [1137, 1136, 865, 849]});
    }
  }

  return (
    <div {...className(['product-grid', searchTerm && 'is-searching'])}>
      {_.map(productGroups, (productGroup, index) => (
        <ProductCell {...{productGroup, groupMode, groupKey, searchTerm, index}} key={index}/>
      ))}
    </div>
  );
}

var ProductCell = ({productGroup, groupMode, groupKey, searchTerm, index}) => {
  var {title, media=[], products} = productGroup;

  if (!_.isEmpty(products)) {
    var {id, title: productTitle} = _.get(_.sortBy(products, 'rank'), `0`);
  }
  else {
    var {id, title: productTitle} = productGroup; //eslint-disable-line
  }

  var uri = utilities.uriForNavigation({groupMode, groupKey, id, title: productTitle, searchTerm});

  var style = {transitionDelay: `${_.clamp(index * 0.15, 0, 1)}s`};

  if (media.length > 1) _.remove(media, {type: 'video'});

  return (
    <Link className='product-cell' to={uri} style={style}>
      <div className='product-cell-image' style={{backgroundImage: `url(${media[0].url})`}}/>
      <div className='product-cell-dimmer'>
        <div className='product-cell-title'>{title}</div>
      </div>
    </Link>
  );
};

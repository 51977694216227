import React from 'react';
import ReactDOM from 'react-dom';
import App from 'components/app/app';
import { lib, AppWrapper, createStore } from '@henrybuilt/react-app/src';
import hbWebsiteLib from 'hb-website-lib';
import * as serviceWorker from './serviceWorker';
import localStorage from 'local-storage';
import queryString from 'query-string';
import { cart } from 'reducers';
import './index.css';
import _ from 'lodash';

if (_.get(queryString.parse(window.location.search), 'allowLocalPickup') === '1') {
  localStorage('allowLocalPickup', '1');
}

if (_.includes(window.location.origin, 'primary-objects.com')) {
  window.location.replace(`https://objects.henrybuilt.com${window.location.pathname}`);
}
else if (window.location.pathname === '/finishes') {
  window.location.replace(`https://henrybuilt-cdn.s3-us-west-2.amazonaws.com/po/finishes-2.pdf`);
}
else {
  var cartState = {productInstances: {}, contactInfo: {}, deliveryAddress: {}};

  if (localStorage('cart')) cartState = JSON.parse(localStorage('cart'));

  var store = createStore({
    reducers: {cart},
    initialState: {cart: cartState, session: {isLoggedIn: false}}
  });

  ReactDOM.render((
    <AppWrapper store={store} appKey='po-ws' hideHeader>
      <App />
    </AppWrapper>
  ), document.getElementById('root'));

  window.lib = lib;
  global.lib = lib;

  window.hbWebsiteLib = hbWebsiteLib;
  global.hbWebsiteLib = hbWebsiteLib;
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

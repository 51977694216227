import {_, Component, React, className} from 'components'; //eslint-disable-line
import {Link} from 'react-router-dom';
import './page-not-found.scss';

export default function PageNotFound() {
  return (
    <div className='page-not-found-wrapper'>
      <div className='page-not-found'>
        <div className='main-copy'>The page you were looking for doesn't exist</div>
        <div className='secondary-copy'>You may have mistyped the address or the page may have moved</div>
        <Link to='/objects/by/type' className='link'>Go to our home page</Link>
      </div>
    </div>
  );
}

import {_, React, Component, className, lib} from 'components'; //eslint-disable-line
import utilities from 'utility/utility-functions';
import './price-display.scss';

var PriceDisplay = ({productInstance, product, user}) => {
  var price = utilities.priceForProductInstance({productInstance, product});

  if (user) var tradePrice = utilities.priceForProductInstance({productInstance, product, user});

  return (
    <div {...className(['price-display', tradePrice && 'trade-price-displayed'])}>
      <div className='standard-price'>{price}</div>
      {tradePrice && <div className='trade-price'>{tradePrice}</div>}
    </div>
  );
};

export default PriceDisplay;

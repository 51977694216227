import {_, React, Component, className, lib} from 'components'; //eslint-disable-line
import Dimensions from 'components/dimensions/dimensions';
import IconGrid from 'components/icon-grid/icon-grid';
import './details.scss';
import utilities from '../../../../../utility/utility-functions';

class DetailsView extends Component {
  state = {
    expandedDetails: {}
  }

  toggleIsExpanded = title => this.setState(oldState => oldState.expandedDetails[title] = !oldState.expandedDetails[title]);

  createDetailsGroups = ({product={}}) => {
    var {
      constraints={}, materialAssociations=[], price, pulls=[], productDetails={},
      installationData={}, installationText: customInstallationText, documents, tradePrice
    } = product;

    var {cleated, requiresAssembly, oversize} = installationData;

    var installationText = '';

    installationText += {
      '0-0': 'This object is freestanding and does not require any assembly. A professional installer is not necessary.',
      '0-1': 'This object is freestanding and requires some assembly. A professional installer is recommended.',
      '1-0': 'This object is cleated to the wall but does not require any assembly. A professional installer is required.',
      '1-1': 'This object is cleated to the wall and requires some assembly. A professional installer is required.'
    }[`${cleated}-${requiresAssembly}`];

    if (oversize) installationText += ` This object may exceed standard doorway size of 32" x 80". Please verify site access prior to ordering.`;

    return [
      {
        title: 'dimensions',
        shouldRender: !_.isEmpty(constraints),
        render: () => <Dimensions product={product} display='block'/>
      },
      {
        title: 'materials',
        shouldRender: !_.isEmpty(materialAssociations),
        render: () => <IconGrid categories={materialAssociations} detailType='material' optionKey='materials' usePopup={true}/>,
      },
      {
        title: 'pulls',
        shouldRender: !_.isEmpty(pulls),
        render: () => <IconGrid categories={[{pulls}]} optionKey='pulls' usePopup={true}/>,
      },
      {
        title: 'other options',
        shouldRender: !_.isEmpty(productDetails),
        render: () => <IconGrid categories={productDetails} optionKey='productDetailOptions' usePopup={true}/>,
      },
      {
        title: 'pricing',
        shouldRender: price,
        render: () => (<div className='pricing-details'>
          <div {...className(['price', tradePrice && 'crossed-out'])}>{price}</div>
          {tradePrice && <div className='price'>{`${tradePrice} (trade & client price)`}</div>}
        </div>)
      },
      {
        title: 'installation',
        shouldRender: installationText || customInstallationText,
        render: () => (
          <div className='installation-details'>
            {installationText && <div>{installationText}</div>}
            {customInstallationText && <div>{customInstallationText}</div>}
          </div>
        )
      },
      {
        title: 'files',
        shouldRender: !_.isEmpty(documents),
        render: () => (<div className='files-details'>
          {_.map(documents, ({id, fileUrl, title, documentType}) => (
            <a className='file-link' key={id} href={`${fileUrl}`} rel='noopener noreferrer' target='_blank'>
              {documents.length < 2 ? _.startCase(documentType) : `${title} (${_.startCase(documentType)})`}
            </a>
          ))}
        </div>)
      }
    ];
  };

  render() {
    var {product, materialClasses} = this.props;
    var detailsGroups = this.createDetailsGroups({product, materialClasses});

    return (
      <div className='details'>
      {_.map(detailsGroups, ({title, shouldRender, render}) => (
        shouldRender && (
          <div key={title} className='details-group'>
            <div className='details-group'>
              <div className='details-group-header' onClick={() => this.toggleIsExpanded(title)}>
                <img className='arrow' src={utilities.cdnLink(`images/icons/resize-${this.state.expandedDetails[title] ?  'up' : 'down'}-arrow.png`)} alt=''/>
                {title}
              </div>
              <div {...className(['detail-body', this.state.expandedDetails[title] && 'is-expanded'])} >
                {render ? render() : null}
              </div>
            </div>
          </div>
          )
        )
      )}
      </div>
    );
  }
}

export default DetailsView;

import {_, Component, React, className} from 'components'; //eslint-disable-line
import './about-page.scss';

export default function AboutPage() {
  return (
    <div className='about-page-wrapper'>
      <div className='about-page'>
        <div className='about-page-title'>Primary Objects</div>
        <div className='about-page-body'>
          <p>Primary Objects is about functional elegance, flexibility, and the quality of everyday experience in a home. It’s also about pushing aesthetic boundaries.</p>
          <p>And it’s about giving you choice. There are 80 products in the collection, with additional products launching each month.</p>
          <p>Primary Objects offers you luxurious building blocks for key parts of the home—in furniture or furniture-like forms that are virtually impossible to find elsewhere. The most uniquely functional pieces are those that include flexible, interchangeable components: highly outfitted <a href='/objects/by/type/islands'>kitchen islands</a>, <a href='/objects/by/type/pantries-shelving'>pantries</a> and <a href='/objects/by/type/armoires-dressers'>armoires</a>, dressing islands, and our Opencase Wall Panel system, which will be available as a Primary Object collection by March.</p>
          <p>As part of a larger Henrybuilt Project, Primary Objects allows you to be playful and take chances—providing an expressive contrast perhaps to a more subtle built-in component. There’s also the convenience factor: You can do your kitchen as a project with us, and purchase vanities from Primary Objects, and have them all deliver together if you want.</p>
          <p>And if you happen to be putting in an Ikea kitchen—and we understand that happens—you can elevate your kitchen into another category by combining it with a Primary Object island or pantry.</p>
          <p className='about-page-header'>BACKGROUND</p>
          <p>For almost 20 years we’ve worked to perfect a family of products that are designed around the daily activities of life in a home, starting with the kitchen but moving into almost every room.  </p>
          <p>Now we’re taking the essence of some of that work and drawing it out; refining it into singular forms that are designed to be both luxurious, and highly interactive, with specialized fittings that you can freely arrange how you wish.</p>
          <p>We are still as focused as ever on evolving our built-in products. The development process for one area helps inform the other.</p>
          <p className='about-page-header'>DESIGNERS</p>
          <p>Scott Hudson is the founder, CEO, and Creative Director of Henrybuilt, who drives the process of creating major new products. But as he will quickly tell you, he is only one member of a large and talented team.</p>
          <p>Many of these products start in Scott’s sketchbook, turn into a prototype, get further engineered, prototyped a second, third, or fourth time, until they become something interesting and good. Other times, the beginning of an idea will come from a client, or another design team member, or it will evolve slowly out of incremental improvement of an existing product.</p>
          <p>An editor recently asked Scott: “Do these ideas get watered down, compromised, or value-engineered as they go through the process?” A good question. The answer was, “No, they get better and many people contribute to that.”</p>
          <p>Products that involve system features like Opencase or interior fittings, for example, require a lot of refinement and engineering to allow them to be interchangeable and practical to make. That process involves engineers, programmers, highly sophisticated craftspeople, people skilled in sourcing rare materials, designers and business-people who know how to anticipate clients’ needs.</p>
          <p>Many companies celebrate the notion of a single creator, and perhaps that is justified. In our culture, we like to put a name to the creation of things.</p>
          <p>For us, that is Henrybuilt.</p>
        </div>
      </div>
    </div>
  );
}
